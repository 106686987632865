import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DealOverview from "../components/DealOverview";
import PoolInfo from "../components/PoolInfo";
import { Web3Context } from "../context/web3Context";

const BorrowerPollDetails = () => {
  const [
    web3Util,
    setWeb3Util,
    user,
    setUser,
    web3Connected,
    setWeb3Connected,
  ] = useContext(Web3Context);
  const { state } = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [amount, setAmount] = useState(0);
  const [borrowerPoolDetails, setBorrowerPoolDetails] = useState(
    state.data ? state.data : {}
  );
  const display = document.getElementById("message");

  const canLend = () => {
    return (borrowerPoolDetails.borrower.toLowerCase() !== user) && (borrowerPoolDetails.status === "OPEN");
  };

  const canRepay = () => {
    return (borrowerPoolDetails.borrower.toLowerCase() === user) && (borrowerPoolDetails.status === "FULL");
  };

  const processLend = async() => {
    let maxLend = borrowerPoolDetails.principal - borrowerPoolDetails.principallent;
    if (amount <= maxLend) {
      try {
        const ctrctLP = web3Util.getLendingPlatformContract();
        const tx = await ctrctLP.lend(borrowerPoolDetails.id, amount);
        const txRslt = await tx.wait();
        const txnHash = txRslt.transactionHash;
        console.log(txnHash);
        display.innerText = `lent succesfully ${txnHash}`;
      } catch (err) {
        console.log(err.reason);
        display.innerText = `failed to lend due to ${err.reason}`;
      }
    } else {
      display.innerText = "lend amount is greater than maximum amount that could be lent";
    }
  };

  const processRepay = async() => {
    try {
      const ctrctLP = web3Util.getLendingPlatformContract();
      const tx = await ctrctLP.repay(borrowerPoolDetails.id);
      const txRslt = await tx.wait();
      const txnHash = txRslt.transactionHash;
      console.log(txnHash);
      display.innerText = `Repaid successfully, transaction hash: ${txnHash}`;
    } catch (err) {
      console.log(err.reason);
    }
  };

  useEffect(() => {
    canLend();
  }, [user]);

  useEffect(() => {
    canRepay();
  }, [user]);

  return (
    <div className=" min-h-full py-8">
      <p id="message"></p>
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <h3 className="font-bold text-[2rem] md:text-5xl leading-8">
          {borrowerPoolDetails.maintitle}
        </h3>
        {canLend() ? (
          <div className="flex gap-1 items-center">
            <input
              type="number"
              name="amount"
              id="amount"
              autoComplete="off"
              onChange={(e) => setAmount(Number(e.target.value))}
              className="py-2 px-3 pr-7 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter..."
            />
            <button
              type="button"
              onClick={processLend}
              className={`w-full mt-8 sm:mt-0 sm:w-32 text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                disabled ? "pointer-events-none opacity-50" : ""
              }`}
            >
              LEND
            </button>
          </div>
        ) : ``}
        {canRepay() ? (
          <button
          type="button"
          onClick={processRepay}
          style={{ background: "rgb(11 164 150)" }}
          className={`w-full mt-8 sm:mt-0 sm:w-32 text-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
            disabled ? "pointer-events-none opacity-50" : ""
          }`}
        >
          Repay
        </button>
        ) : ``}
      </div>
      <div className="mt-8 mb-8">
        <PoolInfo
          principal={borrowerPoolDetails.principal}
          term={borrowerPoolDetails.term}
          interestRate={borrowerPoolDetails.interest}
          principalLent={borrowerPoolDetails.principallent}
          status={borrowerPoolDetails.status}
        />
      </div>
      <div className="mb-8">
        <DealOverview
          title="Loan proposal description"
          description={borrowerPoolDetails.subtitle}
        />
      </div>
    </div>
  );
};

export default BorrowerPollDetails;
