import React, { useReducer } from "react";

let userID = localStorage.getItem("userID")
  ? localStorage.getItem("userID")
  : "";

export const initialState = {
  userID: "" || userID,
  web3Provider: {},
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };

    case "LOGIN_SUCCESS":
      localStorage.setItem("userID", action.payload.userID);
      return {
        ...initialState,
        userID: action.payload.userID,
        web3Provider: action.payload.web3Provider,
        loading: false,
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case "NETWORK_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
