import React from "react";
import Card from "./Card";

const PoolInfo = ({ principal, term, interestRate, principalLent, status }) => {
  return (
    <div className="grid gap-px rounded-xl border border-gray-300 grid-cols-1 sm:grid-cols-3 bg-gray-300 overflow-hidden w-full sm:w-3/4">
      <Card cardTitle="Principal" cardDescription={`${principal} INRC`} />
      <Card
        cardTitle="Term"
        cardDescription={`${term} ${term > 1 ? "Years" : "Year"}`}
      />
      <Card
        cardTitle="Interest Rate"
        cardDescription={`${interestRate / 100.0}%`}
      />
      <Card
        cardTitle="Principal Lent"
        cardDescription={`${principalLent} INRC`}
      />
      <Card cardTitle="status" cardDescription={status} />
      <Card cardTitle="" cardDescription="" />
    </div>
  );
};

export default PoolInfo;
