import { ethers } from "ethers";
const ctrct_details = require("./contract-details.json");

class EthersMetaMaskWallet {

    constructor(window) {
        this.window = window;
        this.provider = null;
        this.account = null;
        this.connected = false;
        if (this.isMetaMaskInstalled()) {
            this.provider = new ethers.providers.Web3Provider(this.window.ethereum);
            this.window.ethereum.on('accountsChanged', this.handleMetaMaskAccountChange);
            this.window.ethereum.on('disconnect', this.handleMetaMaskDisconnection);
        } else {
            throw new Error("failed to detect MetaMask");
        }
    }

    isMetaMaskInstalled() {
        const { ethereum } = this.window;
        return Boolean(ethereum && ethereum.isMetaMask);
    }

    handleMetaMaskDisconnection(error) {
        console.log(`disconnected from blockchain network due to ${error}`);
        this.provider = null;
        this.account = null;
        this.connected = false;
        console.log('please try reloading the page and initiate a connection again');
    }

    handleMetaMaskAccountChange(accounts) {
        let newAccount = accounts[0];
        console.log(`account changed from ${this.account} to ${newAccount}`);
        this.account = newAccount;
    }

    getProvider() {
        if (this.connected) {
            return this.provider;
        } else {
            throw new Error("wallet is not connected to a provider");
        }
    }

    async connect() {
        if (this.connected) {
            return;
        }
        let accounts = await this.provider.send("eth_requestAccounts", []);
        this.account = accounts[0];
        this.connected = true;
    }

    onDisconnect(handler) {
        this.window.ethereum.on('disconnect', handler);
    }

    onAccountSwitch(handler) {
        this.window.ethereum.on('accountsChanged', (accounts) => {
            let newAccount = accounts[0];
            handler(newAccount);
        });
    }

    getLendingPlatformContract() {
        let signer = this.getProvider().getSigner();
        const addrLP = ctrct_details.LendingPlatform.address;
        const abiLP = ctrct_details.LendingPlatform.abi;
        return new ethers.Contract(addrLP, abiLP, signer);
    }
}

export default EthersMetaMaskWallet;