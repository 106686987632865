import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useAuthDispatch, logout, useAuthState, loginUser } from "../context";
import { useNavigate } from "react-router-dom";
import { Web3Context } from "../context/web3Context";
import EthersMetaMaskWallet from "../web3Utility";

// let web3Context;
const Header = () => {
  const [web3Util, setWeb3Util, user, setUser, web3Connected, setWeb3Connected] = useContext(Web3Context);
  const [walletConnectionInitialized, setWalletConnectionIntialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState();

  const handleWalletConnect = async() => {
    setWalletConnectionIntialized(true);
    let injWeb3Util;
    try {
      injWeb3Util = new EthersMetaMaskWallet(window);
      await injWeb3Util.connect();
      setWeb3Util(injWeb3Util);
    } catch (err) {
      console.log(err);
      alert("please install Metamask first, reload the page and try connecting again!");
      setWalletConnectionIntialized(false);
      return;
    }
    setUser(injWeb3Util.account);
    setWeb3Connected(true);
    setUserId(injWeb3Util.account);
    setIsLoggedIn(true);
    injWeb3Util.onAccountSwitch((account) => {
      setUser(account);
      setUserId(account);
    });
    injWeb3Util.onDisconnect((error) => {
      setUser(null);
      setWeb3Connected(false);
      setUserId(null);
      setIsLoggedIn(false);
    });
    setWalletConnectionIntialized(false);
  }

  return (
    <header className="fixed h-16 bg-white shadow w-full px-4 sm:px-8 py-3 flex gap-5 items-center justify-between font-medium z-10">
      <div className="flex gap-5 text-xs sm:text-base">
        <Link to="borrower-pool" className="pt-1.5">
          Borrower Pool
        </Link>
        <Link to="loan-proposal" className="pt-1.5">
          Loan Proposal
        </Link>
        <Link to="dashboard" className="pt-1.5">
          Dashboard
        </Link>
      </div>
      <button
        onClick={handleWalletConnect}
        disabled={isLoggedIn || walletConnectionInitialized}
        className={`ml:auto inline-block font-medium max-w-[140px] text-ellipsis overflow-hidden outline-none py-2.5 px-4 text-xs sm:text-sm rounded-full text-white bg-[#444006] ${
          isLoggedIn && isLoggedIn
            ? "text-[#292524] bg-gray-200"
            : "hover:bg-[#292524]"
        }`}
      >
        {isLoggedIn ? userId : "Connect Wallet"}
      </button>
    </header>
  );
};

export default Header;
