import React from "react";


const DealOverview = ({
    title = '',
    description = '',
}) => {
  return(
     <>
     <h4 className="text-xl font-medium leading-6 text-gray-900 mb-6">{ title}</h4>
     <p className="text-base text-gray-600 ">{ description }</p>
     </>
  )
};

export default DealOverview;
