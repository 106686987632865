import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Web3Context } from "../context/web3Context";

const BorrowerPool = () => {
  let navigate = useNavigate();
  const [
    web3Util,
    setWeb3Util,
    user,
    setUser,
    web3Connected,
    setWeb3Connected,
  ] = useContext(Web3Context);
  const [borrowerPools, setBorrowerPools] = useState([]);

  const getBorrowerPoolDetails = async (ctrctLP, bpID) => {
    const bpd = await ctrctLP.loanInfo(bpID);
    console.log(bpd);
    return {
      id: bpID,
      maintitle: bpd.title,
      subtitle: bpd.description,
      interest: Number(bpd.interestRatePerTenThousand),
      principal: Number(bpd.principal),
      principallent: Number(bpd.principalLent),
      borrower: bpd.borrower,
      term: Number(bpd.termInYears),
      status: bpd.status,
    };
  };

  const getBorrowerPools = async () => {
    if (web3Connected) {
      const ctrctLP = web3Util.getLendingPlatformContract();
      const numBorrowerPools = await ctrctLP.loanProposalsSubmitted();
      const nbp = Number(numBorrowerPools);
      let bps = [];
      for (let i = 1; i <= nbp; i++) {
        console.log("retrieving borrower pool with id", i);
        let bpd = await getBorrowerPoolDetails(ctrctLP, i);
        bps.push(bpd);
      }
      console.log(bps);
      setBorrowerPools(bps);
    } else {
      console.log("Please connect to wallet");
    }
  };

  useEffect(() => {
    getBorrowerPools();
  }, [web3Connected]);

  const viewDetails = (data) => {
    navigate("/borrower-details", { state: { data } });
  };

  return (
    <div className="mx-auto min-h-full  w-full py-8">
      {!web3Connected && (
        <div>
          <h2 className="font-bold  mb-4 md:text-3xl">
            Welcome to the decentralized loan lending app - Where Borrowers and
            Lenders Connect!
          </h2>

          <h3 className="mb-4 md:text-lg">
            Looking for a flexible and secure way to borrow or lend
            cryptocurrency? You've come to the right place. Our platform
            empowers borrowers to set their own loan terms, including loan
            amount, interest rate, loan period, and more. Lenders, on the other
            hand, have the freedom to choose the loan proposals that align with
            their investment goals.
          </h3>
          <h2 className="mb-4 md:text-lg">
            To get started, please connect your MetaMask wallet to enjoy secure
            and seamless transactions. Click the 'Connect Wallet' button and
            unlock the power of decentralized finance at your fingertips.
          </h2>

          <p className="mb-4 md:text-base italic">
            Note: The smart contract has been deployed on the Goerli network.
            Please make sure that your MetaMask network is set to Goerli.
          </p>
        </div>
      )}

      {web3Connected && (
        <div>
          <h2 className="font-bold  mb-4 text-[2rem] md:text-5xl">
            Borrower Pools
          </h2>
          <div className="flex flex-col space-y-4">
            {borrowerPools &&
              borrowerPools.map((pool) => (
                <div
                  onClick={() => viewDetails(pool)}
                  key={pool.id}
                  className="grid grid-rows-[auto] md:grid-cols-[70%_1fr_70px] xl:grid-cols-[60%_1fr_70px] gap-4 items-center rounded bg-[#f5f5f4] p-5 hover:bg-[#e7e5e4] text-[#44403c] cursor-pointer"
                >
                  <div className="flex gap-x-4 items-center">
                    <div className="flex flex-col">
                      <h3 className="block text-lg font-medium text-[#44403c]">
                        {pool.maintitle}
                      </h3>
                      <span>{pool.subtitle}</span>
                    </div>
                  </div>
                  <div className="flex flex-col xl:flex-row">
                    <div className="text-lg font-medium w-[100%] xl:w-[50%]">
                      {pool.interest / 100.0}% INRC
                    </div>
                  </div>
                  <div className="md:text-right">
                    <span
                      className={`inline-block rounded-full border py-1.5 px-3 text-xs font-medium text-white uppercase btn-${pool.status.toLowerCase()}`}
                    >
                      {pool.status}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BorrowerPool;
