import React from "react";

const Card = ({ cardTitle = "", cardDescription = "" }) => {
  return (
    <div className="p-4 bg-white">
      <h4 className="mb-3 flex items-center text-sm text-gray-600">
        {cardTitle}
      </h4>
      <div className="text-xl font-medium text-gray-700 md:text-2xl">
        {cardDescription}
      </div>
    </div>
  );
};

export default Card;
