import React from "react";
import LoanProposalForm from "../components/LoanProposalForm";
import LoanProposalLeftBar from "../components/LoanProposalLeftBar";

const LoanProposal = () => {
  return (
    <div className="mx-auto min-h-full max-w-xl w-full py-8">
      <LoanProposalForm />
    </div>
  );
};

export default LoanProposal;
