import { createContext, useState } from 'react';

//create a context, with createContext api
export const Web3Context = createContext();

const Web3Provider = (props) => {
    // this state will be shared with all components 
    const [web3Util, setWeb3Util] = useState(null);
    const [user, setUser] = useState(null);
    const [web3Connected, setWeb3Connected] = useState(false);

    return (
                // this is the provider providing state
        <Web3Context.Provider value={[web3Util, setWeb3Util, user, setUser, web3Connected, setWeb3Connected]}>
            {props.children}
        </Web3Context.Provider>
    );
};

export default Web3Provider;