import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Web3Context } from "../context/web3Context";

const Dashboard = () => {
  let navigate = useNavigate();
  const [
    web3Util,
    setWeb3Util,
    user,
    setUser,
    web3Connected,
    setWeb3Connected,
  ] = useContext(Web3Context);
  const [lentPools, setLentPools] = useState([]);
  const [createdPools, setCreatedPools] = useState([]);

  const getBorrowerPoolDetails = async (ctrctLP, bpID) => {
    const bpd = await ctrctLP.loanInfo(bpID);
    console.log(bpd);
    return {
      id: bpID,
      maintitle: bpd.title,
      subtitle: bpd.description,
      interest: Number(bpd.interestRatePerTenThousand),
      principal: Number(bpd.principal),
      principallent: Number(bpd.principalLent),
      borrower: bpd.borrower,
      term: Number(bpd.termInYears),
      status: bpd.status,
    };
  };

  const getBorrowerPoolsCreated = async (ctrctLP) => {
    let bpcs = [];
    const bpcIDs = await ctrctLP.loanProposalIDsCreatedByMe();
    for (let i = 0; i < bpcIDs.length; i++) {
      bpcs.push(await getBorrowerPoolDetails(ctrctLP, bpcIDs[i]));
    }
    setCreatedPools(bpcs);
  };

  const getBorrowerPoolsLent = async (ctrctLP) => {
    let bpls = [];
    const bplIDs = await ctrctLP.loanIDsLentByMe();
    for (let i = 0; i < bplIDs.length; i++) {
      bpls.push(await getBorrowerPoolDetails(ctrctLP, bplIDs[i]));
    }
    setLentPools(bpls);
  };

  const getBorrowerPoolsRelated = async () => {
    if (!web3Connected) {
      console.log("Please connect to a wallet!");
      return;
    }
    const ctrctLP = web3Util.getLendingPlatformContract();
    await getBorrowerPoolsCreated(ctrctLP);
    await getBorrowerPoolsLent(ctrctLP);
  };

  useEffect(() => {
    getBorrowerPoolsRelated();
  }, [user]);

  const viewDetails = (data) => {
    navigate("/borrower-details", { state: { data } });
  };

  return (
    <div className="mx-auto min-h-full  w-full py-8">
      <h1 className="font-bold  mb-8 text-[2rem] md:text-5xl">Dashboard</h1>
      <div className="flex gap-8">
        <div className="flex-1">
          <h2 className="font-semibold text-[1.7rem] mb-4">
            Borrower Pools created By Me
          </h2>
          {createdPools &&
            createdPools.map((pool) => (
              <div
                onClick={() => viewDetails(pool)}
                key={pool.id}
                className="rounded bg-[#f5f5f4] p-5 hover:bg-[#e7e5e4] text-[#44403c] cursor-pointer mb-4"
              >
                <div className="mb-2">
                  <h3 className="block text-lg font-medium text-[#44403c]">
                    {pool.maintitle}
                  </h3>
                  <span>{pool.subTitle}</span>
                </div>
                <div className="text-lg font-medium w-[100%] xl:w-[50%] mb-3">
                  {pool.interest / 100.0}% INRC
                </div>
                <div>
                  <span
                    className={`inline-block rounded-full border py-1.5 px-3 text-xs font-medium text-white uppercase btn-${pool.status.toLowerCase()}`}
                  >
                    {pool.status}
                  </span>
                </div>
              </div>
            ))}
        </div>
        <div className="flex-1">
          <h2 className="font-semibold text-[1.7rem] mb-4">
            Borrower Pools lent By Me
          </h2>
          {lentPools &&
            lentPools.map((pool) => (
              <div
                onClick={() => viewDetails(pool)}
                key={pool.id}
                className="rounded bg-[#f5f5f4] p-5 hover:bg-[#e7e5e4] text-[#44403c] cursor-pointer mb-4"
              >
                <div className="mb-2">
                  <h3 className="block text-lg font-medium text-[#44403c]">
                    {pool.maintitle}
                  </h3>
                  <span>{pool.subTitle}</span>
                </div>
                <div className="text-lg font-medium w-[100%] xl:w-[50%] mb-3">
                  {pool.interest / 100.0}% INRC
                </div>
                <div>
                  <span
                    className={`inline-block rounded-full border py-1.5 px-3 text-xs font-medium text-white uppercase btn-${pool.status.toLowerCase()}`}
                  >
                    {pool.status}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
