import React, { useState, useContext } from "react";
import { Web3Context } from "../context/web3Context";

const LoanProposalForm = () => {
  const [web3Util, setWeb3Util, user, setUser, web3Connected, setWeb3Connected] = useContext(Web3Context);

  const [values, setValues] = useState({
    title: "",
    principalAmount: 0,
    interestRate: 0,
    termPeriod: 0,
    description: "",
  });

  const interestRatePerTenThousand = () => {
    if (values.interestRate < 100) {
      return parseFloat(values.interestRate).toFixed(2) * 100;
    } else {
      alert("permitted interest rates are > 0.0 and <= 99.99");
    }
  }

  const createLoanProposal = async() => {
    try {
      const ctrctLP = web3Util.getLendingPlatformContract();
      const tx = await ctrctLP.newLoanProposal(
        values.principalAmount,
        interestRatePerTenThousand(),
        values.termPeriod,
        values.title,
        values.description
      );
      const txRslt = await tx.wait();
      console.log(JSON.stringify(txRslt));
      const txnHash = txRslt.transactionHash;
      // display the transaction hash
      console.log(txnHash);
    } catch (err) {
      console.log("transaction errored!");
      // display the reason why transaction failed
      console.log(err.reason);
    }
  }

  const onHandleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={submit}>
      <div className="px-4 py-5 pt-0 sm:pt-2 sm:px-6 ">
        <div className="sm:grid sm:grid-cols-4">
          <span></span>
          <span className="bg-gray-100 py-3 px-4 mb-5 rounded md:col-span-3 hidden">
            Error message
          </span>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-4">
          <div className="text-sm font-medium text-gray-500 flex items-center">
            Title
          </div>
          <div className="sm:col-span-3">
            <div className="relative">
              <input
                type="text"
                name="title"
                id="title"
                autoComplete="off"
                onChange={onHandleChange}
                className="py-2 px-3 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <span className="text-sm mt-2 ml-1 flex text-gray-500 text-red-600 hidden">
              Title is required !
            </span>
          </div>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-4">
          <div className="text-sm font-medium text-gray-500 flex items-center">
            Principal amount
          </div>
          <div className="sm:col-span-3">
            <div className="relative flex">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 py-2 px-3 text-sm text-gray-500">
                INRC
              </span>
              <input
                type="number"
                name="principalAmount"
                id="principalAmount"
                autoComplete="off"
                onChange={onHandleChange}
                className="py-2 px-3 w-full rounded-none rounded-r-lg rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <span className="text-sm mt-2 ml-1 flex text-gray-500 text-red-600 hidden">
              Principal amount is required !
            </span>
          </div>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-4 ">
          <div className="text-sm font-medium text-gray-500 flex items-center">
            Interest rate
          </div>
          <div className="sm:col-span-3">
            <div className="relative">
              <input
                type="number"
                name="interestRate"
                id="interestRate"
                autoComplete="off"
                onChange={onHandleChange}
                className="py-2 px-3 pr-7 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">%</span>
              </div>
            </div>
            <span className="text-sm mt-2 ml-1 flex text-gray-500 text-red-600 hidden">
              Interest rate is required !
            </span>
          </div>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-4">
          <div className="text-sm font-medium text-gray-500 flex items-center">
            Term Period
          </div>
          <div className="sm:col-span-3">
            <div className="flex relative">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 py-2 px-3 text-sm text-gray-500">
                Years
              </span>
              <input
                type="number"
                name="termPeriod"
                id="termPeriod"
                autoComplete="off"
                onChange={onHandleChange}
                className="py-2 px-3 w-full rounded-none rounded-r-lg rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
            <span className="text-sm mt-2 ml-1 flex text-gray-500 text-red-600 hidden">
              Term period is required !
            </span>
          </div>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-2">
          <div className="text-sm font-medium text-gray-500 flex items-center">
            Description
          </div>
          <div className="sm:col-span-3 relative">
            <textarea
              id="description"
              name="description"
              rows={5}
              onChange={onHandleChange}
              className="py-2 px-3 w-full rounded-md  border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="pb-8 sm:grid sm:grid-cols-4 sm:gap-2">
          <div className="sm:col-span-3 text-right">
            <button
              type="submit"
              onClick={createLoanProposal}
              disabled={!web3Connected}
              className="w-full md:ml-[135px] text-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoanProposalForm;
