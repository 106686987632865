import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import BorrowerpPool from "../pages/BorrowerPool";
import LoanProposal from "../pages/LoanProposal";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BorrowerPollDetails from "../pages/BorrowerPoolDetails";
import Dashboard from "../pages/Dashboard";
import Web3Provider from "../context/web3Context";

const AppRouter = () => {
  return (
    <section className="flex flex-col h-full">
      <Web3Provider>
        <Router>
          <Header />
          <main className="flex-grow px-4 sm:px-8 pt-16 flex flex-col">
            <Routes>
              <Route path="/" element={<Navigate to="/borrower-pool" />} />
              <Route path="/borrower-pool" element={<BorrowerpPool />} />
              <Route
                path="/borrower-details"
                element={<BorrowerPollDetails />}
              />
              <Route path="/loan-proposal" element={<LoanProposal />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </Web3Provider>
    </section>
  );
};

export default AppRouter;
