import React from "react";

const Footer = () => {
  return (
    <footer className="h-16 bg-white border-t border-gray-200 w-full px-6 py-6 md:px-10">
      <a href="https://dune.com/talentica_software/loan-lending-platform-dashboard" className="footer-link" target="_blank">Dune Dashboard</a>
      <a href="https://testnet.thegraph.com/explorer/subgraphs/F2jfvyEGxfyYbYh4fhiwfFtuaVWh1garsLn4UJXYk2e2" className="footer-link" target="_blank">Subgraph</a>
    </footer>
  );
};

export default Footer;
